import { createIntl, type IntlShape, type MessageDescriptor } from 'react-intl';

import logger from 'helpers/logger';

const REQUIRED_ROW_KEYS: Record<string, Set<string>> = {
  subtitle: new Set([
    'payment_abroad',
    'debt_collection',
    'legal_assistance',
    'online_support',
    'employee_access',
    'receipts',
    'payment_terminal',
    'withdrawal_limit',
    'cash_withdrawals',
    'card_payment_limit',
    'bulk_transfers',
    'outgoing_international_transfers',
    'sepa_transfers',
    'virtual_cards',
  ]),
  tag: new Set([
    'fraud_insurance',
    'healthcare_abroad',
    'transport_delays',
    'purchase_protection',
    'urssaf_estimator',
    'cashflow_management_dashboard',
  ]),
  tooltip: new Set([
    'employee_access',
    'phone_support',
    'toolbox',
    'healthcare_abroad',
    'transport_delays',
    'purchase_protection',
    'laptop_insurance',
    'mobile_insurance',
    'cash_deposit',
    'cash_withdrawals',
    'sepa_transfers',
    'virtual_cards',
    'physical_cards',
    'partner_network',
    'contract_library',
    'fraud_insurance',
    'rental_car',
    'accident_insurance',
    'employee_transfers',
    'employee_cards',
    'expense_account_management',
    'accounting_software_integration',
    'accountant_exports',
    'urssaf_estimator',
    'cashflow_management_dashboard',
    'invoice_card_payment',
    'auto_reminder',
    'product_and_services_catalogue',
    'customised_quotes_and_invoices',
    'invoicing_tool',
    'instant_transfers',
    'iban_accounts',
  ]),
};

/**
 * Warning: Using UNSAFE_dynamicFormatMessage is discouraged!
 * This function dynamically handles missing translations by returning 'undefined' for optional fields and logging errors for required ones.
 * However, the use of dynamic IDs may lead to issues such as silent failures or missed translations, making it harder to track missing keys and causing inconsistent UI behavior.
 * Before using, please review your implementation carefully and ensure all translation IDs are statically defined where possible.
 */

export const unsafeDynamicFormatMessage = (
  intl: IntlShape,
  { id }: { id: MessageDescriptor['id'] | string },
) => {
  const silentIntl = createIntl({
    ...intl,
    onError: () => {},
    onWarn: () => {},
  });

  const string = silentIntl.formatMessage({ id } as MessageDescriptor);

  if (string === id) {
    // For given id: "onboarding.pricing_plan_2025.selection.row.${row.key}.subtitle"
    // Parts: ["onboarding", "pricing_plan_2025", "selection", "row", row.key, "subtitle"]
    const parts = id.split('.');

    // Ex: 'employee_access','phone_support',
    const rowKey = parts[parts.length - 2];

    // Ex: "subtitle" | "tag" | "tooltip"
    const fieldType = parts[parts.length - 1];

    if (rowKey && fieldType && REQUIRED_ROW_KEYS[fieldType]?.has(rowKey)) {
      logger.warn(
        `Missing required translation for ID: ${id} (row: ${rowKey}, type: ${fieldType})`,
      );
    }

    return undefined;
  }

  return string;
};
